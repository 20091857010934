import { IonContent, IonLabel, IonPage, IonCard, IonHeader, IonIcon, IonGrid, IonRow, IonCol, IonImg, IonAccordionGroup, IonAccordion, IonItem, IonList, IonButton } from '@ionic/react';
import { arrowDownCircle, heartCircle, heartDislikeOutline, heartOutline, pulseOutline } from 'ionicons/icons';
import { useQuery } from '../hooks';
import { setColor, openEmail, getColors} from '../controll';
import React, { useEffect, useState } from 'react';
import { Buffer } from 'buffer';
import './Results.css';
import lungs from '../assets/icons/lungs-svgrepo-com.svg';
import stethoscope from '../assets/icons/stethoscope-svgrepo-com.svg';
import workload from '../assets/icons/work-bag-svgrepo-com.svg';
import broken_heart from '../assets/icons/broken-heart-svgrepo-com.svg';
import brain from '../assets/icons/brain-svgrepo-com.svg';
import brain_stroke from '../assets/icons/brain-stroke-svgrepo-com.svg';
import scale from '../assets/icons/scale-svgrepo-com.svg';
import skin from '../assets/icons/face-svgrepo-com.svg';
import height from '../assets/icons/height-svgrepo-com.svg';
import people from '../assets/icons/group-of-people-cartoon-variant-svgrepo-com.svg';
import warning from '../assets/icons/warning-svgrepo-com.svg';
import diabetes from '../assets/icons/diabetes-measure-svgrepo-com.svg';
import person from '../assets/icons/running-person-svgrepo-com.svg';
import PopupModal from '../components/PopupModals/PopupModals';
import pdfGenerate from './PdfGenerate';
import { useCookies } from 'react-cookie';





 

 
const Home: React.FC = () => {

 
  

  

  const [resultsData, setResultsData] = useState<any>();
  const [cookies, setCookie] = useCookies();

  const query = useQuery();


 //This is what gets data from URL, I think it is called on init
  useEffect(() => {
   
    const results = query.get('results');
   
 
    try {
      if (!results || results === '') {
       
        return;
      }
 
     
      const resultsBuffer = Buffer.from(results, 'base64');
      const resultsObj = JSON.parse(resultsBuffer.toString());
      
      //set color is a function that will render the colors of the "result" boxes. This function get's called if there is a good object
      setColor(resultsObj)
      console.log(resultsObj)
      setResultsData(resultsObj);

    } 
    //catches error in data
    catch (e) {
     
      console.log(e);

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  //This funcion renders values, default value renderd is 0. Takes in string of object in data and prints it out for n decimal places
  const renderValue = (valueName: string, decimalDigits: number) => {
    //console.log(resultsData[valueName]);
    return resultsData && resultsData[valueName] && !isNaN(resultsData[valueName])
      ? Math.round(resultsData[valueName] * Math.pow(10, decimalDigits)) /
          Math.pow(10, decimalDigits)
      : '0';
  };

  function downloadPDF () {
    const colors = getColors(resultsData);

    let user: any = {};
    user.name = cookies.name;
    user.age = cookies.age;
    user.diabetic = cookies.diabetic;
    user.gender = cookies.gender;
    user.height = cookies.height;
    user.weight = cookies.weight;
    user.hypertension = cookies.hypertension;
    user.medication = cookies.medication;
    user.smoker = cookies.smoker;

    pdfGenerate(resultsData, colors, user);
  }
    



 
  return (
    <IonPage>
    <IonContent>

      <div className='cont'>
        <IonImg src="/assets/img/triada-logo-long.png" class='logo' className='ion-padding'></IonImg>
      </div>

    <IonCard className='results-card card-center ion-text-center'>
        <IonIcon icon={person} size="large" className="ion-padding"></IonIcon>
        <h2>Overall Score: {renderValue('healthScore', 0)}</h2>
          <p>Share results with health coach</p>
          <IonGrid><IonRow className='justify-content: center'><IonCol><IonButton onClick={()=>openEmail(cookies.name)}>Share</IonButton></IonCol></IonRow></IonGrid>  
    
    
    
      <IonButton onClick={downloadPDF}>Download Results</IonButton>

    
    
    </IonCard>
    
      <IonCard className='results-card card-center'>
        <IonAccordionGroup multiple value="vitals">

          {/*-- VITALS --*/}
          <IonAccordion value="vitals" toggleIcon={arrowDownCircle} >
            <IonItem slot="header">
              <IonLabel>Vitals</IonLabel>
            </IonItem>
      
            <IonList slot="content">
              <IonItem>
                <IonIcon icon={heartOutline} size="large" className="ion-padding"></IonIcon>
                <IonLabel className="accord-label">HEART RATE <br /> <p className='accord-sub-label'>60-100 ppm&nbsp;
                <input
                    className="link-popup"          
                    type="button"
                    value="more information"
                    id="trigger-ppm"
                />
                </p> </IonLabel>

                <div className='ion-margin-start ion-padding result-box' id="ppm"><IonLabel>{renderValue('ppm', 0)}</IonLabel></div>
              </IonItem>

              <IonItem >
                <IonIcon icon={heartCircle} size="large" className="ion-padding"></IonIcon>
                <IonLabel className="accord-label ion-text-wrap">IRREGULAR HEARTBEATS<br />
                  <input
                    className="link-popup"          
                    type="button"
                    value="more information"
                    id="trigger-irregularHeartBeats"
                  />
                </IonLabel>
                <div className='ion-margin-start ion-padding result-box' id="irregularHeartBeats"><IonLabel>{renderValue('irregularHeartBeats', 0)}</IonLabel></div>
              </IonItem>

              <IonItem>
                <IonIcon icon={lungs} size="large" className="ion-padding"></IonIcon>
                <IonLabel className="accord-label ion-text-wrap">BREATHING <br /> <p className='accord-sub-label'>12-25 brpm&nbsp;
                <input
                    className="link-popup"          
                    type="button"
                    value="more information"
                    id="trigger-breathing"
                />
                </p></IonLabel>

                <div className='ion-margin-start ion-padding result-box' id="breathing"><IonLabel>{renderValue('breathing', 0)}</IonLabel></div>
              </IonItem>

              <IonItem lines="none">
                <IonIcon icon={stethoscope} size="large" className="ion-padding"></IonIcon>
                <IonLabel className="accord-label ion-text-wrap">BLOOD PREASURE <br /> </IonLabel>

               
              </IonItem>
              <IonItem lines="none">
                
                <IonLabel className="accord-sub-sub-label ion-text-wrap">SYSTOLIC BLOOD PRESSURE <p className='accord-sub-label'>120-129 mmHg&nbsp;
                <input
                    className="link-popup"          
                    type="button"
                    value="more information"
                    id="trigger-systolic"
                />
                </p></IonLabel>

                <div className='ion-margin-start ion-padding result-box' id="systolic"><IonLabel>{renderValue('systolic', 0)}</IonLabel></div>
              </IonItem>
              <IonItem lines="none">
                
                <IonLabel className="accord-sub-sub-label ion-text-wrap">DIASTOLIC BLOOD PRESSURE <p className='accord-sub-label'>80-84 mmHg&nbsp;
                <input
                    className="link-popup"          
                    type="button"
                    value="more information"
                    id="trigger-diastolic"
                />
                </p></IonLabel>

                <div className='ion-margin-start ion-margin-bottom ion-padding result-box' id="diastolic"><IonLabel>{renderValue('diastolic', 0)}</IonLabel></div>
              </IonItem>
              
            </IonList>
          </IonAccordion>

          {/*-- Phsyiological --*/}
          <IonAccordion value="physiological" toggleIcon={arrowDownCircle}>
            <IonItem slot="header">
              <IonLabel>Physiological</IonLabel>
            </IonItem>
      
            <IonList slot="content">
              <IonItem>
                <IonIcon icon={pulseOutline} size="large" className="ion-padding"></IonIcon>
                <IonLabel className="accord-label ion-text-wrap">HEART RATE VARIABILITY <p className='accord-sub-label'>37.9-54 ms&nbsp;
                <input
                    className="link-popup"          
                    type="button"
                    value="more information"
                    id="trigger-heartRateVariability"
                />
                </p></IonLabel>

                <div className='ion-margin ion-padding result-box' id="hrv"><IonLabel>{renderValue('heartRateVariability', 0)}</IonLabel></div>
              </IonItem>

              <IonItem>
                <IonIcon icon={workload} size="large" className="ion-padding"></IonIcon>
                <IonLabel className="accord-label ion-text-wrap">CARDIAC WORKLOAD<p className='accord-sub-label'>dB&nbsp;
                <input
                    className="link-popup"          
                    type="button"
                    value="more information"
                    id="trigger-cardiacWorkload"
                />
                </p> </IonLabel>

                <div className='ion-margin ion-padding result-box' id="cardiacWorkoad"><IonLabel>{renderValue('cardiacWorkload', 0)}</IonLabel></div>
              </IonItem>
              
            </IonList>
          </IonAccordion>

          {/*-- Mental --*/}
          <IonAccordion value="mental" toggleIcon={arrowDownCircle}>
            <IonItem slot="header">
              <IonLabel>Mental</IonLabel>
            </IonItem>
      
            <IonList slot="content">

              <IonItem>
                <IonIcon icon={brain} size="large" className="ion-padding"></IonIcon>
                <IonLabel className="accord-label ion-text-wrap">STRESS INDEX <p className='accord-sub-label'>
                <input
                    className="link-popup"          
                    type="button"
                    value="more information"
                    id="trigger-msi"
                /></p>
                </IonLabel>

                <div className='ion-margin ion-padding result-box' id="msi"><IonLabel>{renderValue('msi', 0)}</IonLabel></div>
              </IonItem>
              
            </IonList>
          </IonAccordion>

          {/*-- Physical --*/}
          <IonAccordion value="physical" toggleIcon={arrowDownCircle}>
            <IonItem slot="header">
              <IonLabel>Physical</IonLabel>
            </IonItem>
      
            <IonList slot="content">

             <IonItem>
                <IonIcon icon={scale} size="large" className="ion-padding"></IonIcon>
                <IonLabel className="accord-label ion-text-wrap">BODY MASS INDEX<p className='accord-sub-label'>18.5-24.9 kg/m<sup>2</sup>&nbsp;
                <input
                    className="link-popup"          
                    type="button"
                    value="more information"
                    id="trigger-bmi"
                /></p></IonLabel>

                <div className='ion-margin ion-padding result-box' id="bmi"><IonLabel>{renderValue('bmi', 0)}</IonLabel></div>
              </IonItem>

              <IonItem>
                <IonIcon icon={skin} size="large" className="ion-padding"></IonIcon>
                <IonLabel className="accord-label ion-text-wrap">FACIAL SKIN AGE<p className='accord-sub-label'>
                <input
                    className="link-popup"          
                    type="button"
                    value="more information"
                    id="trigger-age"
                /></p></IonLabel>

                <div className='ion-margin ion-padding result-box outline-box'><IonLabel>{renderValue('age', 0)}</IonLabel></div>
              </IonItem>

              <IonItem>
                <IonIcon icon={height} size="large" className="ion-padding"></IonIcon>
                <IonLabel className="accord-label ion-text-wrap">WAIST-TO-HEIGHT RATIO<p className='accord-sub-label'>&plusmn;4% of result&nbsp;
                <input
                    className="link-popup"          
                    type="button"
                    value="more information"
                    id="trigger-waistToHeight"
                /></p></IonLabel>

                <div className='ion-margin ion-padding result-box' id="waistToHeight"><IonLabel>{renderValue('waistToHeight', 0)}%</IonLabel></div>
              </IonItem>

              <IonItem>
                <IonIcon icon={people} size="large" className="ion-padding"></IonIcon>
                <IonLabel className="accord-label ion-text-wrap">BODY SHAPE INDEX<p className='accord-sub-label'>
                <input
                    className="link-popup"          
                    type="button"
                    value="more information"
                    id="trigger-absi"
                /></p></IonLabel>

                <div className='ion-margin ion-padding result-box' id="absi"><IonLabel>{renderValue('absi', 0)}</IonLabel></div>
              </IonItem>

              
            </IonList>
          </IonAccordion>

          {/*-- General Risk --*/}
          <IonAccordion value="general_risk" toggleIcon={arrowDownCircle}>
            <IonItem slot="header">
              <IonLabel>General Risk</IonLabel>
            </IonItem>
      
            <IonList slot="content">
              <IonItem>
                <IonIcon icon={heartDislikeOutline} size="large" className="ion-padding"></IonIcon>
                <IonLabel className="accord-label ion-text-wrap">CARDIOVASCULAR DISEASE RISK<p className='accord-sub-label'>
                <input
                    className="link-popup"          
                    type="button"
                    value="more information"
                    id="trigger-cvdRisk"
                /></p></IonLabel>

                <div className='ion-margin ion-padding result-box' id="cvdRisk"><IonLabel>{renderValue('cvdRisk', 2)}%</IonLabel></div>
              </IonItem>

              <IonItem>
                <IonIcon icon={broken_heart} size="large" className="ion-padding"></IonIcon>
                <IonLabel className="accord-label ion-text-wrap">HEART ATTACK RISK<p className='accord-sub-label'>
                <input
                    className="link-popup"          
                    type="button"
                    value="more information"
                    id="trigger-heartAttackRisk"
                /></p></IonLabel>

                <div className='ion-margin ion-padding result-box' id="heartAttackRisk"><IonLabel>{renderValue('heartAttackRisk', 2)}%</IonLabel></div>
              </IonItem>

              <IonItem>
                <IonIcon icon={brain_stroke} size="large" className="ion-padding"></IonIcon>
                <IonLabel className="accord-label ion-text-wrap">STROKE RISK<p className='accord-sub-label'>
                <input
                    className="link-popup"          
                    type="button"
                    value="more information"
                    id="trigger-strokeRisk"
                /></p></IonLabel>

                <div className='ion-margin ion-padding result-box' id="strokeRisk"><IonLabel>{renderValue('strokeRisk', 2)}%</IonLabel></div>
              </IonItem>
            </IonList>
          </IonAccordion>

          {/*-- Metanolic Risk --*/}
        {/*
          <IonAccordion value="metabolic_risk" toggleIcon={arrowDownCircle} >
            <IonItem slot="header">
              <IonLabel>Metabolic Risk</IonLabel>
            </IonItem>
      
            <IonList slot="content">
              <IonItem>
                <IonIcon icon={warning} size="large" className="ion-padding"></IonIcon>
                <IonLabel className="accord-label ion-text-wrap">HYPERTENSION RISK<p className='accord-sub-label'>
                <input
                    className="link-popup"          
                    type="button"
                    value="more information"
                    id="trigger-HypertensionRisk"
                /></p></IonLabel>

                <div className='ion-margin ion-padding result-box' id="HypertensionRisk"><IonLabel>{renderValue('HypertensionRisk', 0)}</IonLabel></div>
              </IonItem>

              <IonItem>
                <IonIcon icon={warning} size="large" className="ion-padding"></IonIcon>
                <IonLabel className="accord-label ion-text-wrap">HYPERTRIGLYCERDEMIA RISK<p className='accord-sub-label'>
                <input
                    className="link-popup"          
                    type="button"
                    value="more information"
                    id="trigger-HypertriglyceridemiaRisk"
                /></p></IonLabel>

                <div className='ion-margin ion-padding result-box' id="HypertriglyceridemiaRisk"><IonLabel>{renderValue('HypertriglyceridemiaRisk', 0)}</IonLabel></div>
              </IonItem>

              <IonItem>
                <IonIcon icon={warning} size="large" className="ion-padding"></IonIcon>
                <IonLabel className="accord-label ion-text-wrap">HYPERCHOLESTROLEMIA RISK<p className='accord-sub-label'>
                <input
                    className="link-popup"          
                    type="button"
                    value="more information"
                    id="trigger-HypercholesterolemiaRisk"
                /></p></IonLabel>

                <div className='ion-margin ion-padding result-box' id="HypercholesterolemiaRisk"><IonLabel>{renderValue('HypercholesterolemiaRisk', 0)}</IonLabel></div>
              </IonItem>

              <IonItem>
                <IonIcon icon={diabetes} size="large" className="ion-padding"></IonIcon>
                <IonLabel className="accord-label ion-text-wrap">TYPE 2 DIABETES RISK<p className='accord-sub-label'>
                <input
                    className="link-popup"          
                    type="button"
                    value="more information"
                    id="trigger-DiabetesRisk"
                /></p>
                </IonLabel>

                <div className='ion-margin ion-padding result-box' id="DiabetesRisk"><IonLabel>{renderValue('DiabetesRisk', 0)}</IonLabel></div>
              </IonItem>
            </IonList>
  </IonAccordion>*/}

        </IonAccordionGroup>
    
        
        </IonCard>

       
       {/*-- All Popup modals are here --*/}
      <PopupModal />

      
      <div className='results-card card-center ion-text-center ion-padding'>
          <IonGrid><IonRow className='justify-content: center'><IonCol><IonButton href='profile'>Measure Again</IonButton></IonCol></IonRow></IonGrid>    
      </div>

    </IonContent>
    
  </IonPage>

  
  );

}
 
export default Home;
